<template>
	<div class="pubpage">
		<div class="headbox" :class="nyCss && inwidth>768 ?'ny_css':''">
			<div class="header content_w">
				<div class="headlt" @click="logoLink()">
					<img src="../../static/img/logo_h.png" alt="" class="logo1" v-if="nyCss" >
					<div v-else>
						<img src="../../static/img/logo.png" alt="" class="logo1" v-if="inwidth>768" >
						<img src="../../static/img/logo2.png" alt="" class="logo1" v-else >
						<!-- <img src="../../static/img/logo2.png" alt="" class="logo2"  > -->
					</div>
				</div>
				<div class="headamo">
					<div class="headlist"  v-if="inwidth>768">
						<div v-for="(item,index) in navlist" :key="index" @click="navChange(index)">
							<router-link :to="item.path" tag="div" class="navitem" :class="navctive===index?'navctive':''" >
								<div class="nav_cont" :style="index===0?'width:50%' : '' ">
								<div class="nav_top"></div>
								{{item.name}}
								<div class="nav_line"></div>
								</div>
							</router-link>
						</div>
					</div>
					<!--  -->
					<div class="lines" @click="navMobile" v-else>
						<!-- @mouseleave="leave()" -->
						<div v-if="navstate"><img src="../../static/img/close1.png" style="width:90%"/></div>
						<div v-else>
							<span></span><span></span><span></span>
						</div>
					</div>
				</div>
				
				<div class="headnav" v-if="inwidth<=768&&navstate" >
                    <div v-for="(item,index) in navlist" :key="index">
                        <router-link :to="{path: item.path == '/about/0' || item.path == '/subsidiray/0' ? '':item.path}" @click.native="homeLink(item.name,item.subnavList)" tag="div">
                        <div class="navitem" :class="navctive==index?'navctive':''">
                            <div  @click="change(index,item.subnavList)">{{item.name}}</div>
							<!-- aa &&  -->
								<div v-if="closeNav && navctive==index" class="subnav_box">
									<div v-for="(item1,index1) in item.subnavList" :key="index1" >
										<router-link :to="{path:item1.path}" tag="div"> 
										<div class="navitem subnavbg" :class="navctive==index1?'navctive':''" @click="subchange(index1,item1.name)" >
											{{item1.name}}
										</div>
										</router-link>
									</div>
								</div>
                        </div>
                        </router-link>
                    </div>
                </div>
				<div class="headrt">
					<div>
						<img src="../../static/img/icon.png" alt="" class="logo1">
						<img src="../../static/img/icon_1.png" alt="" class="logo2">
					</div>
				</div>
			</div>
		</div>
		<router-view></router-view>
	<div class="section footH">
      <div class="footbox">
        <div class="footer content_w">
          <div class="footlt">
            <div class="footlogo">
              <img src="../../static/img/logo.png" alt="" />
            </div>
            <ul class="footma_ul">
              <li>
                <img src="../../static/img/ft_icon1.png" alt="" />
                <span>
                  {{webInfo.title4}}
                </span>
              </li>
              <li>
                <img src="../../static/img/ft_icon2.png" alt="" />
                {{webInfo.title2}}
              </li>
              <li>
                <img src="../../static/img/ft_icon3.png" alt="" />
                {{webInfo.title3}}
              </li>
            </ul>
          </div>
          <ul class="footul">
            <li>
              <router-link :to="{name:'about',query:{navctiveIndex:0}}" class="litop" tag="div">关于我们</router-link>
              <div class="foot_line"></div>
              <router-link :to="{name:'about',query:{navctiveIndex:0}}" tag="div">公司介绍</router-link>
              <!-- <router-link :to="{name:'about',query:{navctiveIndex:0,selector:'gdjs'}}" tag="div">股东优势</router-link> -->
              <router-link :to="{name:'about',query:{navctiveIndex:1}}" tag="div">组织架构</router-link>
              <!-- <router-link :to="{name:'about',query:{navctiveIndex:0,selector:'hzhb'}}" tag="div">合作伙伴</router-link> -->
            </li>
            <li>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:0}}" class="litop" tag="div">子公司</router-link>
              <div class="foot_line"></div>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:0}}" tag="div">业务主体子公司</router-link>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:1}}" tag="div">供应链服务公司</router-link>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:2}}" tag="div">产业链实体投资</router-link>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:3}}" tag="div">境外贸易公司</router-link>
            </li>
            <li>
              <router-link :to="{name:'field',query:{navctiveIndex:0}}" class="litop" tag="div">业务领域</router-link>
              <div class="foot_line"></div>
              <router-link :to="{name:'field',query:{navctiveIndex:0}}" tag="div">能源化工</router-link>
              <router-link :to="{name:'field',query:{navctiveIndex:1}}" tag="div">有色金属</router-link>
              <router-link :to="{name:'field',query:{navctiveIndex:2}}" tag="div">农产品/软商品</router-link>
              <router-link :to="{name:'field',query:{navctiveIndex:3}}" tag="div">工业品</router-link>
            </li>
            <li>
              <router-link to="/news" class="litop" tag="div">新闻资讯</router-link>
              <div class="foot_line"></div>
            </li>
            <li>
              <router-link to="/hr" class="litop" tag="div">人才招聘</router-link>
              <div class="foot_line"></div>
              <!-- <router-link to="/hr" tag="div">人才理念</router-link> -->
              <router-link to="/hr" tag="div">招聘信息</router-link>
            </li>
            <li>
              <router-link to="/contact" class="litop" tag="div">联系我们</router-link>
              <div class="foot_line"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="footbtm">
        <div class="foot_cont content_w">
          <div>{{webInfo.site_copy}}</div>
          <div>备案号：<a href="https://beian.miit.gov.cn" target="_blank" class="a_color">{{webInfo.miitbeian}}</a> 浙公安网备：{{webInfo.beian}}</div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			closeNav:true,
			cid:1,
			aa:false,
			navctive:this.$route.query.navctiveIndex,
			navstate:false,
			webInfo:{},
			nyCss:true,//判断是否为内页
			navlist:[
				{
					name:'首页',
					path:'/',
					subnavList:[]
				},
				{
					name:'关于我们',
					path:'/about/0',
					subnavList:[{
                        name:'公司介绍',
                        path:'/about/0',
                    },
                    {
                        name:'组织架构',
                        path:'/about/1',
                    },]
				},
				{
					name:'子公司',
					path:'/subsidiray/0',
					subnavList:[{
                        name:'业务主体子公司',
                        path:'/subsidiray/0',
                    },
                    {
                        name:'供应链服务公司',
                        path:'/subsidiray/1',
                    },{
                        name:'产业链延伸实体公司',
                        path:'/subsidiray/2',
                    },{
                        name:'供应链服务公司',
                        path:'/subsidiray/3',
                    },]
				},
				{
					name:'业务领域',
					path:'/field',
					subnavList:[]
				},
				{
					name:'新闻资讯',
					path:'/news',
					subnavList:[]
				},
				{
					name:'人才招聘',
					path:'/hr',
					subnavList:[]
				},{
					name:'联系我们',
					path:'/contact',
					subnavList:[]
				},
			],
			inwidth: window.innerWidth,
			inheight:window.innerHeight,
			footdata:{},
			navbg:false,
			pagea: '',//当前页数
			delivery_asn:'',
			
		}
	},
	created(){
		//解决刷新首页导航变内页导航
		if(this.$route.path != '/'){
			this.nyCss = true
		}else{
			this.nyCss = false
		}
		let that = this;
		window.addEventListener('setItem',()=> {
			that.pagea = sessionStorage.getItem('watchStorage');
		})
	},
	watch:{
		$route(to,from){
			//清除监听
			window.removeEventListener("scroll", this.onScroll, true);
			if(to.name != "home"){
				this.nyCss = true
			}else{
				this.nyCss = false
			}
			console.log('to.meta.title',to.meta.title)
			//解决点击底部进入页面头部选中状态
			if(to.meta.title){
				if(to.meta.title == '关于我们'){
					this.navctive = 1;
				}else if(to.meta.title == '子公司'){
					this.navctive = 2;
				}else if(to.meta.title == '业务领域'){
					this.navctive = 3;
				}else if(to.meta.title == '新闻资讯' || to.meta.title == '新闻详情'){
					this.navctive = 4;
				}else if(to.meta.title == '人才招聘' || to.meta.title == '人才招聘详情'){
					this.navctive = 5;
				}else if(to.meta.title == '联系我们'){
					this.navctive = 6;
				}else{
					this.navctive = 0;
				}
			}
		},
		pagea(to,from){
		  if(to=='page6'){
			  $('.headbox').addClass('dis_play')	
			}else{
				$('.headbox').removeClass('dis_play')	
			}
		}
	},
	mounted(){
		this.navctive = this.navlist.findIndex(k => k.path === this.$route.path) || 0
        if (this.inwidth >= 1024) {
            window.addEventListener('scroll', this.onScroll)
		}
		this.web_info();
		
    },
	methods:{
		change(index,subnavlist){
			this.closeNav = true
			this.navctive = index
			this.cid = index+1
			if(subnavlist && subnavlist.length>0){
				this.navstate = true
			}else{
				this.navstate = false
			}
		},
		subchange(index){
			this.navctive = index
			this.cid = index+1
			this.navstate = false
		   
		},
		homeLink(name,subnavlist){
			if(name == '首页'){
				window.location.href = '/'
			}
			if(subnavlist && subnavlist.length>0){
				this.aa = !this.aa
			}
		},
		navChange(index){
			console.log('index',index)
			this.navctive = index
		},
		logoLink(){
            this.$router.push('/')
		},
		web_info() {
			var that = this;
			that.$api.web_info({}).then(res => {
				if (res.status == 1) {
					this.webInfo = res.data
				} else {
				  layer.msg(res.msg);
				}
			});
		},
		onScroll(){
			let scrollTop =window.pageYOffset ||document.documentElement.scrollTop ||
				document.body.scrollTop;  
			if(scrollTop>0){
			}else{
			}
		},
		navMobile() {
			this.navstate = !this.navstate
			if(this.navstate){
				this.closeNav = false;
			}
		},
	},
	beforeDestroy() {
		//清除监听
		let that=this
		window.removeEventListener("scroll", that.onScroll, true);
	}
}
</script>

<style scoped>

.headrt{
	display: none;
}
.headbox{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: .5rem;
	background: rgba(255,255,255,0);
	z-index: 2000;
	color:#FFFFFF;
	padding-top: .1rem;
	/* border-bottom: .01rem solid rgba(255,255,255,0.2); */
}
.headboxbg{
	background: rgba(255,255,255,0);
	color:#333;
}
.header{
	width: 90%;
	height: .5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.headlt	{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: .1rem;
}
.headlt	img{
	width: 1.22rem;
	display: block;
	cursor: pointer;
}
.headamo{
	height: .5rem;
	display: flex;
	justify-content:flex-end;
	float: right;
	flex: 1;
}
.headlist{
	display: flex;
	justify-content: space-between;
}
.headlist .navitem{
	margin: 0 0.30rem;
	/* padding:.1rem 0; */
	line-height: .5rem;
	/* border-bottom: .03rem solid transparent; */
	font-size: .14rem;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	cursor: pointer;
	position: relative;
	width: .6rem;
	height:.5rem;
}
.nav_cont{
	width: 100%;
	position: absolute;
	text-align: center;
	color: rgba(255,255,255,1);
	transition: color .3s ease-in-out;
}
.nav_top{
	width: 100%;
    height: 0;
	transition: height .3s ease-in-out;
}
.nav_line{
	left: 0;
	right: 0;
	position:absolute;
	width: 0%;
	height: .03rem;
	background: rgba(255,255,255,0);
	transition: width .3s ease-in-out;
}
.navitem:hover .nav_line{
	width: 100%;
	/* bottom: .03rem; */
	background: #e60012;
}

.navitem:hover .nav_top{
	height: 6px;
}
.navitem:hover .nav_cont{
	color: rgba(255,255,255,1);
}

.headlist .navctive .nav_cont{
	padding-top: 0px;
	border-bottom: .03rem solid #e60012;
	color: rgba(255,255,255,1);
}
.headlist .navctive.navctive1{
	padding-top: 0px;
	border-bottom: .03rem solid #e60012;
	color: rgba(255,255,255,1);
}
.headlist .navctive1{
	margin: 0 auto;
}
.headrt{
	height: 1.0rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: .16rem;
	display: none;
}
.headrt div{
	padding:.1rem 0;
	margin-left: 0.1rem;
	cursor: pointer;
}
.headrt img{
	width: .3rem;
	margin-left: 0.3rem;
}
.headrt .headlogo{
	font-weight: bold;
}
.hs_color{
	color: #333!important;
	border-bottom: .01rem solid #000;
}
.dis_play{
	display: none;
}
.logo1{
	display: block!important;
}
.logo2{
	display: none!important;
}
.hs_color .logo1{
	display: none!important;
}
.hs_color .logo2{
	display: block!important;
}
.ny_css{
	background: rgba(255,255,255,1);
	height: 60px;
}
.ny_css .nav_cont{
	color:#333;
	
}

.ny_css .headlist .navctive .nav_cont{
	color: #333;
}
.ny_css .navitem:hover .nav_cont{
	color: #333;
	
}
.ny_css .navitem:hover .nav_top{
	height:0px;
} 
/* .ny_css .nav_line{
	bottom: -.1rem;
} */
.ny_css .headlist .navitem{
	line-height: .57rem;
}



/*当浏览器尺寸匹配时候的代码：*/
@media screen and (max-width:1410px) {
	.headlist .navitem{
		margin: 0 0.14rem;
	}
	.footul li{
		padding: 0 0.1rem;
		margin-left: 0.2rem;
	}
	.footul{
		margin-left: 5%;
	}
	
}
@media screen and (max-width:1200px) {
    .footul li{
		padding: 0 0.1rem;
		margin-left: 0.15rem;
	}
	.footul{
		margin-left: 2%;
	}
}
@media screen and (max-width:768px) {
	.lines {
		width: 25px;
		position: relative;
		z-index: 9999;
	}
	.lines span {
		width: 100%;
		height: 2px;
		background: #000;
		display: block;
		margin-bottom: 4px;
	}
	.lines span:last-child {
		margin-bottom: 0;
	}
	.headnav{
	width: 100%;
	padding: 1rem 0 3rem;
	position: absolute;
	top: 4.9rem;
	left: 0;
	background: #fff;
	
}
.navitem{
		width: 90%;
		margin: 0 auto;
		font-size: 14px;
		text-align: center;
		padding: .25rem 0;
		color: #666;
		border-bottom: 1px dashed #e5e5e5;
		cursor: pointer;
	}
.navitem:hover{
	color: #a21003;
}
.navctive{
	background: #eee;
}
.headnav{
	top: 0;
}
.headlt{
	position: relative;
	z-index: 999;
}
.headbox{
background: #fff;
padding-bottom: .1rem;
display: block;
}
.lines{
	margin-top:15px;
}
.headlt{
	margin-top: 0;
}

.footul{
		display: none;
	}
	.footer .footlt{
		width: auto;
		display: flex;
		justify-content: space-between;
	}
	.footer .footlogo img{
		width: 1.3rem;
	}
	.footma_ul{
		flex: 1;
		margin-left: .5rem;
	}
	.footma_ul li{
		line-height: 24px;
	}
	.footma_ul li img{
		width: .25rem;
		height: .25rem;
		margin-right: .2rem;
		margin-top: 2px;
	}
	.foot_cont div{
		width: 100%;
		text-align: center;
	}
	.foot_cont div:last-child{
		display: none;
	}
	.footbtm{
		height: .6rem;
		line-height: .6rem;
	}

}
@media screen and (max-width:480px) {
	.lines{
		margin-top:6px;
	}
	.footer .footlogo img{
		width: 2rem;
	}
	.footma_ul li img{
		width: .35rem;
		height: .35rem;
	}
	
}

</style>
